<template>
 <div v-if="perms.includes('Facturation Fournisseurs') ||  perms.includes('Admin')">
   <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
  <section class="invoice-preview-wrapper" v-else>

    <b-row
      v-if="rows"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <!-- <logo /> -->
                  <h3 class="text-primary invoice-logo">
                    Bon d'Avoir Fournisseur
                  </h3>
                </div>
                <p class="card-text mb-25">
                  {{settings.NomSociete}}
                </p>
                <p class="card-text mb-25">
                  {{settings.desc}}
                </p>
                <p class="card-text mb-0">
                   {{settings.phone}} {{settings.adresse}}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                   N° :
                  <span class="invoice-number"> {{ rows.NumBA }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    AGADIR LE:
                  </p>
                  <p class="invoice-date">
                    {{ rows.DateBA }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

         
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row >

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  FOURNISSEUR :
                </h6>
                <h4 class="mb-25">
                  {{ rows.NomSociete }}
                </h4>
                <p class="card-text mb-25">
                  <b>ICE :</b> {{ rows.Ice }}
                </p>
                <p class="card-text mb-25">
                  <b>TEL :</b> {{rows.phone}}
                </p>
                <p class="card-text mb-25">
                  <b>ADRESSE :</b> {{rows.adresse}}
                </p>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="rows.Products"
            :fields="['ref_prod', 'Nom', 'quantite', 'Prix_Achat','Total']"
          >
            <template #cell(ref_prod)="data">
              <b-card-text class="font-weight-bold mb-25">
                <b>{{ data.item.ref_prod }}</b>
              </b-card-text>
            </template>
            <template #cell(quantite)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.qnt }}
              </b-card-text>
            </template>

            <template #cell(Prix_Achat)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.Prix_Achat }} DH
              </b-card-text>
            </template>

             <template #cell(Total)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.tot }} DH
              </b-card-text>
            </template>
          </b-table-lite>

         <hr class="my-50">
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <b-card-body class="invoice-padding pt-0">
                   <b-col 
                cols="12"
                md="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper" style="max-width: 100rem;">
                 
                
                      
                    <label for="textarea-no-auto-shrink">NOTE :</label>
                <b-form-textarea
                    rows="2"
                    max-rows="8"
                    no-auto-shrink
                    :value="rows.note"
                   
                />
                
                  
                 
                </div>
              </b-col>
                </b-card-body>

              </b-row>
            </b-card-body>

        
        </b-card>
      </b-col>
       <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2" >
        
          <b-card>
            
            <h3>Facture à télecharger</h3><br/>
          <!-- <div class="d-flex justify-content-between align-items-center">
            <label for="HP">En-tête & Pièces Jointes</label>
          
            <b-form-radio
              id="HP"
              v-model="selected"
              value="HP"
              unchecked-value="0"
              switch
            />
          </div> -->

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="HnP">Avec En-tête </label>
            
            <b-form-radio
              id="HnP"
              v-model="selected"
              value="HnP"
              unchecked-value="0"
              switch
            />
          </div>

          <!-- Payment Stub -->
          <!-- <div class="d-flex justify-content-between align-items-center my-1">
            <label for="nHP">Juste Pièces Jointes</label>
          
            <b-form-radio
              id="nHP"
              v-model="selected"
              value="nHP"
              unchecked-value="0"
              switch
            />
          </div> -->

          <div class="d-flex justify-content-between align-items-center">
            <label for="nHnP">Sans En-tête </label>
           
            <b-form-radio
              id="nHnP"
              v-model="selected"
              value="nHnP"
              switch
            />
          </div><br/><br/>
         
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="FactPDF(rows.NumBA)"
          >
            Télécharger PDF
          </b-button>
           <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'BonAvoir-edit', params: { id: $route.params.id } }"
          >
            Modifier Bon d'avoir
          </b-button>

          <!-- Button: Print -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button> -->
     
        </b-card>
      

       
      </b-col>
     
    </b-row>

     <!-- <invoice-sidebar-send-invoice /> -->
    <!-- <invoice-sidebar-add-payment /> -->
  </section>
  </div>
  <div v-else>
  <not-authoriz></not-authoriz>
</div>
</template>

<script>
import config from '@/config'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BFormTextarea,BFormRadio
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from '../FactureFournisseur/invoiceStoreModule' 
import NotAuthoriz from '../NotAuthoriz.vue'



export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,BFormTextarea,BFormRadio,
    Logo,
    NotAuthoriz
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  data(){
    return{
      perms:[],
      loading:false,
      rows:{},
      count:0,
      settings:{},
       selected:0,
    }

  },
  mounted(){
     this.perms=JSON.parse(localStorage.getItem('access'));
       this.$http.get(`${config.API_BASE_URL}/api/auth/GetSettings`)
        .then(res => {  this.settings=res.data})
          this.loading=true
    this.$http.get(`${config.API_BASE_URL}/api/auth/ShowBA/${router.currentRoute.params.id}`)
      .then(res => { this.rows = res.data,   this.loading=false
       })

        this.$mousetrap.bind('ctrl+p', this.logIt);
  },
 methods:{
   logIt(e) {
        this.$http.get(`${config.API_BASE_URL}/api/auth/getBAPDF/${router.currentRoute.params.id}/nHnP`,{responseType: 'arraybuffer'})
                .then(response => { 
                  //  console.log(response.data)
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'BA_N'+'.pdf'
                    link.click()
                })
        return false;
      },
         FactPDF(num){
          if(this.selected == false ){
            this.$swal.fire({
                text: "Merci de choisir le type du bon d'avoir à télecharger !!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `D'accord`
                })
          }else{
              this.$http.get(`${config.API_BASE_URL}/api/auth/getBAPDF/${router.currentRoute.params.id}/${this.selected}`,{responseType: 'arraybuffer'})
                .then(response => { 
                  //  console.log(response.data)
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'BA_N'+num+'.pdf'
                    link.click()
                })
          }
         
        
      }
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then(response => {
        invoiceData.value = response.data.invoice
        paymentDetails.value = response.data.paymentDetails
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })


    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
